export default function scrollDown() {
    var scrollBtn = document.querySelector('section.section-hero a.scroll-down');

    if (scrollBtn) {
        var nextSection = scrollBtn.closest('section').nextElementSibling;
        if (nextSection.classList.contains('icon-labels')) {
            nextSection = nextSection.nextElementSibling;
        }
        nextSection.id = "scroll-down-target";
        scrollBtn.setAttribute('href', '#' + nextSection.id);
    }
}