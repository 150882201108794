export function accordion() {
    document.querySelectorAll('.accordion .title').forEach(title => {
        title.addEventListener('click', () => {
            const accordionItem = title.parentElement;
            const isActive = accordionItem.classList.contains('active');
    
            // Close all accordion items
            document.querySelectorAll('.accordion .accordion-item').forEach(item => {
                item.classList.remove('active');
            });
    
            // Toggle the clicked accordion item
            if (!isActive) {
                accordionItem.classList.add('active');
            }
        });
    });       
}
