import {accordion} from './components/accordion';
import {swiperDesktop, swiperMobile} from './components/swiper';
import {hamburger, mobileHeader, stickyHeader, submenuClick} from './components/header';
import { viewAnimation, smoothScroll } from "./util/animation";
import {outputMonth, countDown} from './util/dates';
import {inlineVideo} from './util/video';
import {phoneConcat, readMore} from './util/text';
import {cf7Redirect} from './util/window';
import collapsible from './components/collapsible';
import modal from './components/modal';
import {tabs} from './components/tabs';
import scrollDown from './util/scrollDown';
import popup from './components/popup';

(function($) {
    $(window).on('load', function () {
        collapsible();
        modal();
        mobileHeader();
        stickyHeader();
        submenuClick();
        hamburger();
        outputMonth();
        viewAnimation();
        accordion();
        swiperDesktop();
        swiperMobile();
        smoothScroll();
        inlineVideo();
        phoneConcat();
        tabs();
        scrollDown();
        popup();
    });


})( jQuery );
