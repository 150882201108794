export default function popup() {
    // Select all elements with the popup-trigger class
    const popupTriggers = document.querySelectorAll('.popup-trigger');

    popupTriggers.forEach(trigger => {
        trigger.addEventListener('click', (event) => {
            event.preventDefault();
            // Get the data-popup-target value from the clicked trigger
            const targetId = trigger.getAttribute('data-popup-target');
            
            if (targetId) {
                // Find the corresponding popup with the matching data-popup-id
                const popup = document.querySelector(`.popup-overlay[data-popup-id="${targetId}"]`);
                
                if (popup) {
                    // Add the 'open' class to the popup
                    popup.classList.add('open');
                }
            }
        });
    });

    // Add functionality to close the popup when clicking a close button
    const closeButtons = document.querySelectorAll('.close-button');

    closeButtons.forEach(button => {
        button.addEventListener('click', () => {
            // Find the nearest parent with the class 'popup-overlay'
            const popup = button.closest('.popup-overlay');
            
            if (popup) {
                // Remove the 'open' class from the popup
                popup.classList.remove('open');
            }
        });
    });
}
