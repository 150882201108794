export function tabs() {
    var section = document.querySelector('section.content-tabs');

    if (section) {
        var controls_wrapper = section.querySelector('.controls');
        var content_wrapper = section.querySelector('.content');

        content_wrapper.querySelectorAll('.tab').forEach((e, i) => {
            e.setAttribute('index', i);
        });

        controls_wrapper.querySelectorAll('div').forEach((e, i) => {
            e.setAttribute('index', i);
            e.addEventListener('click', () => {
                // remove active from all control buttons
                controls_wrapper.querySelectorAll('div').forEach((e) => {
                    e.classList.remove('active');   
                });
                // make clicked control active
                e.classList.add('active');

                // make corresponding tab active
                content_wrapper.querySelectorAll('.tab').forEach((e) => {
                    if (e.classList.contains('active') && e.getAttribute('index') != i) {
                        e.classList.remove('active');
                    } else if (e.getAttribute('index') == i) {
                        e.classList.add('active');
                    }
                });
            });
        });

        // default first items to active 
        controls_wrapper.querySelector('div[index="0"]').classList.add('active');
        content_wrapper.querySelector('.tab[index="0"]').classList.add('active');
    } 
}