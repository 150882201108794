export function mobileHeader() {
    var headerItems = document.querySelectorAll('.menu-item-has-children') 

    if( !headerItems.length ) return;
    
    for(var i = 0; i < headerItems.length; i++) {
        headerItems[i].addEventListener('click', function(e) { 
            if(e.target.nodeName != 'A'){
                this.classList.toggle('active');
            }
         });
    }
}

export function hamburger() {
    $('.site-header .hamburger').on('click', function(){
        $('body').toggleClass('menu-open');
        $('.slideout-menu-container').toggleClass('open');
    });
}

export function stickyHeader() {
    $(window).on("scroll load", function () {
        if ($(window).scrollTop() >= 50) {
            $('.site-header').addClass('scrolled');
        } else {
            $('.site-header').removeClass('scrolled');
        }
    });
}

export function submenuClick() {
    $('.slideout-menu-container ul ul + a').on('click', function(e) {
        // Measure the text portion
        const range = document.createRange();
        range.selectNodeContents(e.target.firstChild); // Assume the text is the first child
        const textRect = range.getBoundingClientRect();

        // Compare click coordinates to text bounds
        if (e.clientX < textRect.left || e.clientX > textRect.right || e.clientY < textRect.top || e.clientY > textRect.bottom) {
            e.preventDefault();
            e.target.closest('li').querySelector(':scope > ul').classList.toggle('open');   
        }
    });
}